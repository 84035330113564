<template>
  <div
    class="modal fade bd-example-modal-lg"
    id="leaguePopup"
    tabindex="-1"
    role="dialog"
    aria-labelledby="leaguePopupLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 id="modal-team-title" class="modal-title">
            <img
              v-if="type == 'football'"
              src="/images/icon-football.png"
              width="38"
              class="p-1"
            />
            <img
              v-if="type == 'basketball'"
              src="/images/icon-basketball.png"
              width="38"
              class="p-1"
            />
            {{ $t("basketball.league-details.league-cup-information") }}
          </h5>

          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 text-nowrap">
              <div
                id="myListLeague"
                role="tablist"
                class="list-group list-group-horizontal overflow-auto text-nowrap"
              >
                <a
                  data-toggle="list"
                  href="#league01"
                  role="tab"
                  class="list-group-item list-group-item-action active"
                  >{{ $t("basketball.league-details.information") }}</a
                >
                <a
                  data-toggle="list"
                  href="#league02"
                  role="tab"
                  class="list-group-item list-group-item-action"
                  >{{ $t("common.menu.standing") }}</a
                >
              </div>
            </div>
            <div class="col-12">
              <table v-if="loading" class="table text-center">
                <tbody>
                  <tr>
                    <td>
                      <br />
                      <br />
                      <img
                        src="/images/spinner.gif"
                        alt="spinner"
                        style="width: 50px"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="tab-content p-2" v-else>
                <div id="league01" role="tabpanel" class="tab-pane active">
                  <table
                    class="table table-sm table-striped table-hover text-center"
                  >
                    <tbody
                      class="table-bordered"
                      v-if="
                        leagueDetails &&
                        leagueDetails.name &&
                        leagueDetails.cn_name
                      "
                    >
                      <tr>
                        <td
                          class="text-white p-2 text-left text-white"
                          :style="`background-color:${
                            leagueDetails.primary_color
                              ? leagueDetails.primary_color
                              : 'black'
                          };`"
                        >
                          {{
                            this.$i18n.locale == "cn"
                              ? leagueDetails.cn_name
                              : leagueDetails.name
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bg-white">
                          <div class="row p-0 m-0">
                            <div
                              class="col-12 col-md-12"
                              style="
                                border-bottom: 0px solid rgb(0, 0, 0);
                                padding-bottom: 10px;
                                padding-top: 10px;
                              "
                            >
                              <div class="row player-detail-list">
                                <div class="col-12 col-md-12 text-center p-2">
                                  <img :src="leagueDetails.logo" width="80" />
                                </div>
                              </div>
                              <div class="row player-detail-list">
                                <label class="col-5 col-md-6 text-right"
                                  >{{
                                    $t("basketball.league-details.full-name")
                                  }}
                                  :
                                </label>
                                <div class="col-7 col-md-6 text-left">
                                  {{
                                    this.$i18n.locale == "cn"
                                      ? leagueDetails.cn_name
                                      : leagueDetails.name
                                  }}
                                </div>
                              </div>
                              <div class="row player-detail-list">
                                <label class="col-5 col-md-6 text-right"
                                  >{{
                                    $t("basketball.league-details.abbreviation")
                                  }}
                                  :
                                </label>
                                <div class="col-7 col-md-6 text-left">
                                  {{
                                    this.$i18n.locale == "cn"
                                      ? leagueDetails.cn_name
                                      : leagueDetails.name
                                  }}
                                </div>
                              </div>
                              <div class="row player-detail-list">
                                <label class="col-5 col-md-6 text-right"
                                  >{{ $t("basketball.league-details.type") }} :
                                </label>
                                <div class="col-7 col-md-6 text-left">
                                  <span v-if="leagueDetails.type == 1">
                                    {{ $t("football.league-details.league") }}
                                  </span>
                                  <span v-else-if="leagueDetails.type == 2">
                                    {{
                                      $t("football.league-details.cup")
                                    }}</span
                                  >
                                  <span v-else>
                                    {{ $t("league") }}
                                  </span>
                                </div>
                              </div>

                              <div class="row player-detail-list">
                                <label class="col-5 col-md-6 text-right"
                                  >{{ $t("basketball.league-details.country") }}
                                  :
                                </label>
                                <div class="col-7 col-md-6 text-left">
                                  <span
                                    v-if="
                                      leagueDetails &&
                                      leagueDetails['countryDetails']
                                    "
                                  >
                                    {{
                                      this.$i18n.locale == "cn"
                                        ? leagueDetails["countryDetails"][
                                            "cn_name"
                                          ]
                                        : leagueDetails["countryDetails"][
                                            "name"
                                          ]
                                    }}
                                  </span>
                                  <span v-else> - </span>
                                </div>
                              </div>

                              <div class="row player-detail-list">
                                <label class="col-5 col-md-6 text-right"
                                  >{{
                                    $t(
                                      "basketball.league-details.current-champion-team"
                                    )
                                  }}
                                  :
                                </label>
                                <div class="col-7 col-md-6 text-left">
                                  <template
                                    v-if="
                                      leagueDetails['current_champion_team']
                                    "
                                  >
                                    {{
                                      leagueDetails["current_champion_team"] ||
                                      "-"
                                    }}
                                  </template>
                                  <template v-else>
                                    <span>- </span>
                                  </template>
                                </div>
                              </div>

                              <div class="row player-detail-list">
                                <label class="col-5 col-md-6 text-right"
                                  >{{
                                    $t(
                                      "basketball.league-details.most-winning-team"
                                    )
                                  }}
                                  :
                                </label>
                                <div class="col-7 col-md-6 text-left">
                                  <template
                                    v-if="leagueDetails['most_champion_team']"
                                  >
                                    <span
                                      v-for="(
                                        mostWinLeague, index
                                      ) in leagueDetails['most_champion_team']"
                                      :key="index"
                                    >
                                      {{ mostWinLeague || "-" }}
                                    </span>
                                  </template>
                                  <template v-else>
                                    <span>- </span>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="!loading && !leagueDetails">
                      {{
                        $t("basketball.live-score.temporarily-no-data")
                      }}
                    </tbody>
                  </table>
                </div>
                <!--tab-pane-- league02 -->
                <div class="tab-pane" id="league02" role="tabpanel">
                  <div class="row">
                    <div class="col-12">
                      <div
                        v-if="
                          leagueDetails &&
                          leagueDetails.standings &&
                          leagueDetails.standings.length !== 0
                        "
                      >
                        <table
                          class="table table-striped table-sm table-bordered table-hover text-center custom-mobile"
                        >
                          <thead>
                            <tr class="bg-info text-white">
                              <th>{{ $t("standings.rank") }}</th>
                              <th>{{ $t("standings.team-name") }}</th>
                              <th>{{ $t("standings.points") }}</th>
                              <th>{{ $t("standings.goals-for") }}</th>
                              <th>{{ $t("standings.goals-against") }}</th>
                              <th>{{ $t("standings.goals-draw") }}</th>
                              <th>{{ $t("standings.win") }}</th>
                              <th>{{ $t("standings.draw") }}</th>
                              <th>{{ $t("standings.loss") }}</th>
                            </tr>
                          </thead>
                          <tbody
                            v-for="(
                              standings, index
                            ) in leagueDetails.standings"
                            :key="index"
                          >
                            <tr class="text-dark">
                              <td>{{ standings.position }}</td>

                              <td>
                                {{
                                  this.$i18n.locale == "cn"
                                    ? standings.teamInfo.cn_name
                                    : standings.teamInfo.name
                                }}
                              </td>

                              <td>{{ standings.won }}</td>
                              <td>{{ standings.draw }}</td>
                              <td>{{ standings.loss }}</td>
                              <td>{{ standings.loss }}</td>
                              <td>{{ standings.loss }}</td>
                              <td>{{ standings.loss }}</td>
                              <td>{{ standings.loss }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        v-if="
                          (!loading && !leagueDetails) ||
                          !leagueDetails.standings ||
                          leagueDetails.standings.length == ''
                        "
                      >
                        <center>
                          <br />
                          <span>
                            {{
                              $t("basketball.live-score.temporarily-no-data")
                            }}
                          </span>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- league02  -->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary">
            {{ $t("football.live-score.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    leagueId: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },

  mounted() {
    let that = this;
    window.$("#leaguePopup").on("shown.bs.modal", function () {
      that.leagueDetails = null;
      console.log("Showw");
      that.getLeagueDetails();
    });

    window.$("#leaguePopup").on("hidden.bs.modal", function () {
      that.leagueDetails = null;
    });
  },
  data() {
    return {
      matchList: null,
      leagueDetails: null,
      loading: false,
    };
  },
  methods: {
    getLeagueDetails() {
      this.loading = true;
      axios
        .get(
          `${this.BASE_SERVER_URI}/api/${this.type}/league-details/${this.leagueId}`
        )
        .then((response) => {
          this.leagueDetails = response.data.leagueDetails[0];
          console.log("-----------> League Details", this.leagueDetails);
          this.loading = false;
        })
        .catch(() => {
          console.log("------> League Details Error");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
