import { createRouter, createWebHistory } from "vue-router";
import WorldStanding from "./components/football/world-standing";
import LiveScoring from "./components/football/live-scoring";
import MatchSchedule from "./components/football/match-schedule";
import MatchResult from "./components/football/match-result";
import BasketBallLiveScoring from "./components/basketball/live-scoring";
import BasketBallMatchResult from "./components/basketball/match-result";
import BasketBallMatchSchedule from "./components/basketball/match-schedule";
import StandingPhone from "./components/standings/phone/standings-phone";
import StandingDesktop from "./components/standings/desktop/standings-desktop";
import AlternateUrl from "./components/pages/alternate-url";
import DownloadApp from "./components/pages/download-app";
import SportsNews from "./components/post/post-list";
import SpecificPost from "./components/post/post-details";
import POSTBYTAG from "./components/post/post-by-tag";
import VIDEOINDEX from "./components/video/video-index";
import LIVESTREAMING from "./components/live/live-anchor-list";
import LIVEANCHOR from "./components/live/live-anchor";
import VIDEO from "./components/video/video-show";
import i18n from "./i18n";
// aa

let pathName = location.pathname;
let domianName = location.href;
// if (pathName.indexOf("cn/") != -1 || domianName.indexOf("cn.") != -1)
//   i18n.global.locale = "cn";

// if (pathName.indexOf("ty/") != -1 || domianName.indexOf("ty.") != -1 || domianName.indexOf("id.") != -1)
//   i18n.global.locale = "ty";

// if (pathName.indexOf("vi/") != -1 || domianName.indexOf("vi.") != -1 || domianName.indexOf("vn.") != -1)
//   i18n.global.locale = "vi";

// if (pathName.indexOf("th/") != -1 || domianName.indexOf("th.") != -1)
//   i18n.global.locale = "th";


if (pathName.indexOf("cn/") != -1){
  i18n.global.locale = "cn";
}else if(pathName.indexOf("ty/") != -1){
  i18n.global.locale = "ty";
}else if(pathName.indexOf("vi/") != -1){
  i18n.global.locale = "vi";
}else if(pathName.indexOf("th/") != -1){
  i18n.global.locale = "th";
}else if(pathName.indexOf("en/") != -1){
  i18n.global.locale = "en";
}else{
  if(domianName.indexOf("cn.") != -1){
    i18n.global.locale = "cn";
  }else if(domianName.indexOf("ty.") != -1){
    i18n.global.locale = "ty";
  }else if(domianName.indexOf("vn.") != -1){
    i18n.global.locale = "vi";
  }else if(domianName.indexOf("th.") != -1){
    i18n.global.locale = "th";
  }else{
    i18n.global.locale = "en";
  }
  
}



let hostname = window.location.hostname;
//==========================================================
let hostA = '77577.live';
let hostAPosition = hostname.search(hostA);
if(hostAPosition != -1){
  if(i18n.global.locale == "en"){
    if(hostname != 'en.77577.live'){
      window.location.href = "https://en.77577.live/";
    }
  }
  if(i18n.global.locale == "cn"){
    if(hostname != 'cn.77577.live'){
      window.location.href = "https://cn.77577.live/";
    }
  }
  if(i18n.global.locale == "ty"){
    if(hostname != 'ty.77577.live'){
      window.location.href = "https://ty.77577.live/";
    }
  }
  if(i18n.global.locale == "vi"){
    if(hostname != 'vn.77577.live'){
      window.location.href = "https://vn.77577.live/";
    }
  }
  if(i18n.global.locale == "th"){
    if(hostname != 'th.77577.live'){
      window.location.href = "https://th.77577.live/";
    }
  }
}
//==========================================================
let hostB = '77577.com';
let hostBPosition = hostname.search(hostB);
if(hostBPosition != -1){
  if(i18n.global.locale == "en"){
    if(hostname != 'en.77577.com'){
      window.location.href = "https://en.77577.com/";
    }
  }
  if(i18n.global.locale == "cn"){
    if(hostname != 'cn.77577.com'){
      window.location.href = "https://cn.77577.com/";
    }
  }
  if(i18n.global.locale == "ty"){
    if(hostname != 'id.77577.com'){
      window.location.href = "https://id.77577.com/";
    }
  }
  if(i18n.global.locale == "vi"){
    if(hostname != 'vn.77577.com'){
      window.location.href = "https://vn.77577.com/";
    }
  }
  if(i18n.global.locale == "th"){
    if(hostname != 'th.77577.com'){
      window.location.href = "https://th.77577.com/";
    }
  }
}


// console.log(hostname);
// console.log(pathName);
// console.log(i18n.global.locale);

let hostC = 'bifen-802.com';
let hostCPosition = hostname.search(hostC);
if(hostCPosition != -1){
  console.log("called here ");
  if(i18n.global.locale == "en"){
    if(hostname != 'en.bifen-802.com'){
      window.location.href = "http://en.bifen-802.com/";
    }
  }
  if(i18n.global.locale == "cn"){
    if(hostname != 'cn.bifen-802.com'){
      window.location.href = "http://cn.bifen-802.com/";
    }
  }
  if(i18n.global.locale == "ty"){
    if(hostname != 'ty.bifen-802.com'){
      window.location.href = "http://ty.bifen-802.com/";
    }
  }
  if(i18n.global.locale == "vi"){
    if(hostname != 'vn.bifen-802.com'){
      window.location.href = "http://vn.bifen-802.com/";
    }
  }
  if(i18n.global.locale == "th"){
    if(hostname != 'th.bifen-802.com'){
      window.location.href = "http://th.bifen-802.com/";
    }
  }
}
//==========================================================



const routes = [
  {
    path: "/",
    redirect: "/" + i18n.global.locale + "/live/football",
  },
  {
    path: "/" + i18n.global.locale + "/live/football",
    component: LiveScoring,
  },
  {
    path: "/" + i18n.global.locale + "/live/football/world-standing",
    component: WorldStanding,
  },
  {
    path: "/" + i18n.global.locale + "/live/football/match-schedule",
    component: MatchSchedule,
  },

  {
    path: "/" + i18n.global.locale + "/live/football/match-result",
    component: MatchResult,
  },
  {
    path: "/" + i18n.global.locale + "/live/basketball",
    component: BasketBallLiveScoring,
  },
  {
    path: "/" + i18n.global.locale + "/live/basketball/match-result",
    component: BasketBallMatchResult,
  },
  {
    path: "/" + i18n.global.locale + "/live/basketball/match-schedule",
    component: BasketBallMatchSchedule,
  },
  {
    path: "/" + i18n.global.locale + "/alternate-url",
    component: AlternateUrl,
  },
  {
    path: "/" + i18n.global.locale + "/download-app",
    component: DownloadApp,
  },
  {
    path: "/" + i18n.global.locale + "/standings/index",
    name: "standing",

    get component() {
      if (window.screen.width < 768) {
        return StandingPhone;
      } else {
        return StandingDesktop;
      }
    },
  },
  {
    path: "/" + i18n.global.locale + "/post/index",
    component: SportsNews,
  },
  {
    path: "/" + i18n.global.locale + "/post/:slug/:ty",
    component: SpecificPost,
  },
  {
    path: "/" + i18n.global.locale + "/post/tag/:tag/",
    component: POSTBYTAG,
  },
  {
    path: "/" + i18n.global.locale + "/video/index",
    component: VIDEOINDEX,
  },
  {
    path: "/" + i18n.global.locale + "/video/:ty",
    component: VIDEO,
  },
  {
    path: "/" + i18n.global.locale + "/live/anchor",
    component: LIVESTREAMING,
  },
  {
    path: "/" + i18n.global.locale + "/live/anchor/:ty",
    component: LIVEANCHOR,
  },
];
// VIDEOINDEX
export default createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
});
