<template>
  <div class="container fix-frame" style="min-height: 500px">
    <br />
    <div class="row">
      <div class="col-12">
        <h2>{{ $t("common.menu.alternate-url") }}</h2>
      </div>

      <div
        v-for="(list, key) in alternateURL"
        :key="key"
        class="col-12 col-md-6"
      >
        <div class="card pt-2 pb-2 mb-2" style="border: solid 1px #333">
          <div class="row g-0 p-0 m-0">
            <div class="col-3 col-md-3 cus-fontsize">
              {{ calculateMS() }}
            </div>
            <div class="col-5 col-md-5 cus-fontsize">
              {{ list }}
            </div>
            <div class="col-4 col-md-4">
              <a class="btn btn-primary" :href="'//' + list" target="_blank">
                {{ $t("common.menu.visit") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- begin::loading section -->
    <center v-if="loading">
      <br />
      <p style="font-size: 15px !important">
        {{ $t("football.live-score.information-is-loading") }}
        <img src="/images/spinner.gif" alt="spinner" style="width: 12px" />
      </p>
    </center>
    <!-- end::loading section -->
  </div>
</template>
<script>

export default {
  components: {

  },
  data() {
    return {
      alternateURL: null,
      loading: true,
    };
  },
  created() {
    this.$axios.get(`https://bbs.77577.live/api/v1/setting`)
      .then((data) => {
        this.alternateURL = data.data["alternateURL"][this.$i18n.locale];
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    calculateMS() {
      return Math.ceil(Math.random() * 160) + " ms";
    },
  },
};
</script>

<style scoped>
.card:hover {
  color: #000;
  background-color: rgb(223, 223, 223);
}
@media (max-width: 430px) {
  .cus-fontsize {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 991px) {
  .fix-frame {
    width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .fix-frame {
    width: 1000px;
    margin: auto;
  }
}
</style>
