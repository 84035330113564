<template>
  <span v-if="checkDownloadAppPage">
    <MENUBAR />
  </span>

  <router-view></router-view>

  <span v-if="checkDownloadAppPage">
    <br />
    <FOOTER />
  </span>
</template>
<script>
import MENUBAR from "./components/commons/menu-bar.vue";
import FOOTER from "./components/commons/footer-section.vue";
export default {
  components: {
    MENUBAR,
    FOOTER,
  },
  computed: {
    checkDownloadAppPage() {
      return window.location.pathname.includes("download-app") ? false : true;
    },
  },
};
</script>