require('./bootstrap');

import { createApp } from 'vue';
import i18n from './i18n';
import router from './router';
import App from './App.vue';
import axios from "axios";
import videojs from "video.js";
import Popper from "vue3-popper";
import serverCnfg from '../config/server';
import { VideoPlayer } from 'vue-videojs7';



const vueApp = createApp(App);

vueApp.config.globalProperties.BASE_SERVER_URI = serverCnfg.BASE_SERVER_URI;
vueApp.config.globalProperties.$axios = axios;
vueApp.config.globalProperties.$videojs = videojs;
vueApp.config.globalProperties.$Popper = Popper;
vueApp.use(VideoPlayer,)


vueApp.use(router);
vueApp.use(i18n);
vueApp.mount('#app');




