<template>
  <br />
  <!-- begin::Mobile Version -->
  <div class="container-fluid mobile-version">
    <h2>{{ $t("page-post.sport-news") }}</h2>
    <div class="row m-0 p-0 mt-2">
      <div
        class="col-md-6 p-1"
        v-for="poststitle in postsList"
        :key="poststitle.id"
      >
        <div class="card">
          <div class="row g-0 p-0 m-0">
            <div class="col-md-4 p-0 m-0">
              <a href="" @click="titleClicked(poststitle)"
                ><img :src="poststitle.path" alt="" class="img-fluid p-2"
              /></a>
            </div>
            <div class="col-md-8 p-0 m-0">
              <div class="card-body p-2">
                <h5
                  class="card-title"
                  style="font-size: 1.2rem; margin-bottom: 0.15em"
                >
                  <a href="" @click="titleClicked(poststitle)"> </a>
                </h5>
                <p class="card-text" style="font-size: 0.8rem">
                  <a
                    href=""
                    @click="titleClicked(poststitle)"
                    style="font-size: 15px !important"
                  >
                    {{ poststitle.title }}
                  </a>
                </p>
                <div class="d-flex justify-content-between">
                  <div style="font-size: 0.8rem">
                    <small class="text-muted">
                      {{
                        new Date(poststitle.create_time).toLocaleString([], {
                          hour12: false,
                          hour: "2-digit",
                          minute: "2-digit",
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                      }}</small
                    >
                  </div>
                  <div>
                    <a
                      href=""
                      @click="titleClicked(poststitle)"
                      class="btn btn-primary btn-sm"
                    >
                      {{ $t("page-post.more") }}..</a
                    >
                  </div>
                </div>
                <p class="card-text">
                  <span
                    style="margin-left: 2px !important"
                    v-for="(keyword, index) in poststitle.keywords
                      .replaceAll('，', ',')
                      .split(',')"
                    :key="index"
                  >
                    <a
                      href=""
                      @click="tagClicked(keyword)"
                      class="badge badge-dark"
                      >{{ keyword }}</a
                    >
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3" v-if="postsMeta">
      <div class="col-12">
        <div class="d-flex justify-content-center" style="cursor: pointer">
          <paginate
            :page-count="postsMeta ? postsMeta.lastPage : 0"
            :page-range="3"
            :margin-pages="2"
            :click-handler="pagination"
            :prev-text="'‹'"
            :next-text="'›'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
          <br />
        </div>
      </div>
    </div>
    <div class="row mt-3" v-if="loading">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <img src="/images/spinner.gif" style="width: 20px" />
        </div>
      </div>
    </div>
  </div>
  <!-- end::Mobile Version -->
  <!-- begin:: Desktop Version -->
  <div class="container-fluid pc-version" style="min-height: 400px !important">
    <div class="row m-0 p-0 mt-2">
      <div class="col-md-2 d-md-block"></div>
      <div class="col-12 col-md-8 m-0 p-0">
        <div class="d-none d-md-block"></div>
        <div class="row p-0 m-0 mb-3">
          <div class="col-12 p-0 mt-2">
            <div class="row p-0 m-0">
              <div class="col-md-12">
                <h2>{{ $t("page-post.sport-news") }}</h2>
              </div>
              <div
                class="col-md-6 p-1"
                v-for="poststitle in postsList"
                :key="poststitle.id"
              >
                <div
                  class="card"
                  style="height: 233px !important; overflow-y: hidden"
                >
                  <div class="row g-0 p-0 m-0">
                    <div class="col-md-4 p-0 m-0">
                      <a href="" @click="titleClicked(poststitle)"
                        ><img :src="poststitle.path" class="img-fluid p-2"
                      /></a>
                    </div>
                    <div class="col-md-8 p-0 m-0">
                      <div class="card-body p-2">
                        <h5
                          class="card-title"
                          style="font-size: 1.2rem; margin-bottom: 0.15em"
                        >
                          <a href="" @click="titleClicked(poststitle)"> </a>
                        </h5>
                        <div
                          class="card-text"
                          style="font-size: 0.8rem; min-height: 60px"
                        >
                          <a
                            href=""
                            @click="titleClicked(poststitle)"
                            style="font-size: 15px !important"
                          >
                            {{ poststitle.title }}
                          </a>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div style="font-size: 0.8rem">
                            <small class="text-muted">
                              {{
                                new Date(poststitle.create_time).toLocaleString(
                                  [],
                                  {
                                    hour12: false,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                  }
                                )
                              }}
                            </small>
                          </div>
                          <div>
                            <a
                              href=""
                              @click="titleClicked(poststitle)"
                              class="btn btn-primary btn-sm"
                              >{{ $t("page-post.more") }}..</a
                            >
                          </div>
                        </div>
                        <p class="card-text" style="min-height: 90px">
                          <span
                            style="margin-left: 2px !important"
                            v-for="(keyword, index) in poststitle.keywords
                              .replaceAll('，', ',')
                              .split(',')"
                            :key="index"
                          >
                            <a
                              href=""
                              @click="tagClicked(keyword)"
                              class="badge badge-dark"
                              >{{ keyword }}</a
                            >
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="postsMeta">
              <div class="col-12 d-none d-md-block">
                <div
                  class="d-flex justify-content-center"
                  style="cursor: pointer"
                >
                  <paginate
                    :page-count="postsMeta ? postsMeta.lastPage : 0"
                    :page-range="10"
                    :margin-pages="2"
                    :click-handler="pagination"
                    :prev-text="'‹'"
                    :next-text="'›'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </paginate>
                  <br />
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="loading">
              <div class="col-12 d-md-block">
                <div class="d-flex justify-content-center">
                  <img
                    src="/images/spinner.gif"
                    alt="Image not Found"
                    style="width: 20px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2 d-md-block"></div>
    </div>
  </div>
  <!-- end::Desktop Version -->
  <FOOTER />
</template>

<script>

import router from "../../router";
import Paginate from "vuejs-paginate-next";
export default {
  components: {

    Paginate,
  },
  created() {
    console.log(this.$i18n.locale);
    this.getPostsList();
  },
  data() {
    return {
      postsList: null,
      postsMeta: null,
      pageNum: 1,
      loading: false,
    };
  },
  methods: {
    getPostsList() {
      this.loading = true;
      this.$axios
        .get(
          `https://77577.live/api/post-list/${this.$i18n.locale}/${this.pageNum}`
        )
        .then((response) => {
          this.postsList = response.data.list;
          this.postsMeta = response.data.meta;
          this.loading = false;
        })
        .catch(() => {});
    },
    titleClicked(post) {
      router.push(`/${this.$i18n.locale}/post/${post.slug_url}/${post.id}`);
    },
    tagClicked(tag) {
      router.push(`/${this.$i18n.locale}/post/tag/${tag}`);
    },
    pagination(pageNum) {
      this.pageNum = pageNum;
      this.getPostsList();
    },
  },
};
</script>
<style scoped>
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Tablets, iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Tablets, iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}
</style>

