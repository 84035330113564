<template>
  <div
    class="modal fade bd-example-modal-lg"
    id="teamInformationPopup"
    tabindex="-1"
    role="dialog"
    aria-labelledby="teamInformationPopupLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 id="modal-team-title" class="modal-title">
            <img
              v-if="type == 'football'"
              src="/images/icon-football.png"
              width="38"
              class="p-1"
            />
            <img
              v-if="type == 'basketball'"
              src="/images/icon-basketball.png"
              width="38"
              class="p-1"
            />
            {{ $t("basketball.team-details.team-information") }}
          </h5>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <!-- <div class="col-12 text-nowrap">
              <div class="col-12">
         
                <div
                  id="myListLeague"
                  role="tablist"
                  class="list-group list-group-horizontal overflow-auto"
                >
                  <a
                    data-toggle="list"
                    href="#team01"
                    role="tab"
                    class="list-group-item list-group-item-action active"
                    >{{ $t("basketball.team-details.team") }}</a
                  >
                  <a
                    data-toggle="list"
                    href="#team02"
                    role="tab"
                    class="list-group-item list-group-item-action"
                    >{{ $t("basketball.team-details.player") }}</a
                  >
                </div>
           
              </div>
            </div> -->
            <div class="col-12">
              <!-- begin::panel buttons -->
              <div
                id="myListLeague"
                role="tablist"
                class="list-group list-group-horizontal overflow-auto"
              ></div>
              <!-- end::panel buttons -->
            </div>
            <div class="col-12">
              <div class="tab-content p-2">
                <!-- begin::panel 01 -->
                <div id="team01" role="tabpanel" class="tab-pane active">
                  <div>
                    <table
                      class="table table-sm table-striped table-hover text-center"
                    >
                      <tbody v-if="teamDetails" class="table-bordered">
                        <tr class="bg-primary text-white">
                          <th colspan="2">
                            {{ $t("basketball.team-details.team-information") }}
                          </th>
                        </tr>
                        <tr>
                          <td class="bg-white">
                            <div class="row p-0 m-0">
                              <div
                                class="col-12 col-md-12"
                                style="
                                  border-bottom: 0px solid rgb(0, 0, 0);
                                  padding-bottom: 10px;
                                  padding-top: 10px;
                                "
                              >
                                <div class="row player-detail-list">
                                  <div class="col-12 col-md-12 text-center p-2">
                                    <img :src="teamDetails.logo" width="80" />
                                  </div>
                                </div>
                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-6 text-right">
                                    {{
                                      $t("basketball.team-details.team-name")
                                    }}
                                    :</label
                                  >
                                  <div class="col-7 col-md-6 text-left">
                                    {{
                                      this.$i18n.locale == "cn"
                                        ? teamDetails.cn_name || "-"
                                        : teamDetails.name || "-"
                                    }}
                                  </div>
                                </div>

                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-6 text-right"
                                    >{{
                                      $t("basketball.team-details.abbreviation")
                                    }}
                                    :</label
                                  >
                                  <div class="col-7 col-md-6 text-left">
                                    {{
                                      this.$i18n.locale == "cn"
                                        ? teamDetails.cn_name || "-"
                                        : teamDetails.short_name || "-"
                                    }}
                                  </div>
                                </div>

                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-6 text-right"
                                    >{{
                                      $t("basketball.team-details.country")
                                    }}
                                    :</label
                                  >
                                  <div class="col-7 col-md-6 text-left">
                                    <span
                                      v-if="
                                        teamDetails['countryDetails'] &&
                                        teamDetails['countryDetails'][0]
                                      "
                                    >
                                      {{
                                        this.$i18n.locale == "cn"
                                          ? teamDetails["countryDetails"][0]
                                              .name || "-"
                                          : teamDetails["countryDetails"][0]
                                              .name || "-"
                                      }}
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </div>

                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-6 text-right"
                                    >{{
                                      $t("basketball.team-details.head-coach")
                                    }}
                                    :</label
                                  >
                                  <div class="col-7 col-md-6 text-left">
                                    <span
                                      v-if="
                                        teamDetails['coach_details'] &&
                                        teamDetails['coach_details'][0]
                                      "
                                    >
                                      {{
                                        this.$i18n.locale == "cn"
                                          ? teamDetails["coach_details"][0][
                                              "name"
                                            ] || "-"
                                          : teamDetails["coach_details"][0][
                                              "name"
                                            ] || "-"
                                      }}
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </div>
                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-6 text-right"
                                    >{{
                                      $t("basketball.league-details.website")
                                    }}
                                    :</label
                                  >
                                  <div class="col-7 col-md-6 text-left">
                                    <span>
                                      {{ teamDetails.website || "-" }}
                                    </span>
                                  </div>
                                </div>
                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-6 text-right"
                                    >{{
                                      $t(
                                        "basketball.league-details.total-players"
                                      )
                                    }}
                                    :</label
                                  >
                                  <div class="col-7 col-md-6 text-left">
                                    <span>
                                      {{ teamDetails.total_players || "-" }}
                                    </span>
                                  </div>
                                </div>
                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-6 text-right"
                                    >{{
                                      $t(
                                        "basketball.league-details.foreign-players"
                                      )
                                    }}
                                    :</label
                                  >
                                  <!-- {{ $t("basketball.live-score.temporarily-no-data") }} -->
                                  <div class="col-7 col-md-6 text-left">
                                    <span>
                                      {{ teamDetails.foreign_players || "-" }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <span v-else-if="!loading">
                        <br />
                        {{ $t("basketball.live-score.temporarily-no-data") }}
                      </span>
                    </table>
                  </div>
                </div>
                <!-- <div id="team02" role="tabpanel" class="tab-pane">
                  <div>
                    <table
                      class="table table-sm table-striped table-hover text-center"
                    >
                      <tbody class="table-bordered" v-if="playersDetails">
                        <tr v-for="players in playersDetails" :key="players.id">
                          <td>
                            <div class="row p-0 m-0 pt-2 pb-2">
                              <div class="col-12 col-md-6">
                                <div class="row player-detail-list">
                                  <div class="col-12 col-md-12 p-2">
                                    <img
                                      :src="
                                        players.logo
                                          ? players.logo
                                          : '/images/player.png'
                                      "
                                      width="80"
                                    />
                                  </div>
                                </div>
                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-6 text-right"
                                    >{{
                                      $t("basketball.team-details.player-name")
                                    }}
                                    :</label
                                  >
                                  <div class="col-7 col-md-6 text-left">
                                    {{
                                      this.$i18n.locale == "cn"
                                        ? players.name || "-"
                                        : players.name || "-"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-md-6">
                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-4 text-right"
                                    >{{
                                      $t("basketball.team-details.position")
                                    }}
                                    :</label
                                  >
                                  <div class="col-7 col-md-8 text-left">
                                    {{ players.position || "-" }}
                                  </div>
                                </div>
                                <div class="row player-detail-list">
                                  <label class="col-5 col-md-4 text-right"
                                    >{{
                                      $t("basketball.team-details.jersey-no")
                                    }}
                                    :</label
                                  >
                                  <div class="col-7 col-md-8 text-left">
                                    {{ players.shirt_number || "-" }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <span v-else-if="!loading">
                        <br />
                        {{ $t("basketball.live-score.temporarily-no-data") }}
                      </span>
                    </table>
                  </div>
                </div> -->

                <!--tab-pane-- league02 -->
                <!-- <div class="tab-pane" id="league02" role="tabpanel">
                  <div class="row">
                    <div class="col-12">
                      <div>
                        <table
                          class="table table-striped table-sm table-bordered table-hover text-center custom-mobile"
                        >
                          <thead>
                            <tr class="bg-info text-white">
                              <th>{{ $t("standings.rank") }}</th>
                              <th>{{ $t("standings.team-name") }}</th>
                              <th>{{ $t("standings.points") }}</th>
                              <th>{{ $t("standings.goals-for") }}</th>
                              <th>{{ $t("standings.goals-against") }}</th>
                              <th>{{ $t("standings.goals-draw") }}</th>
                              <th>{{ $t("standings.win") }}</th>
                              <th>{{ $t("standings.draw") }}</th>
                              <th>{{ $t("standings.loss") }}</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- league02  -->
                <!-- end::panel 01 -->

                <table v-if="loading" class="table text-center">
                  <tbody>
                    <tr>
                      <td>
                        <br />
                        <b></b>
                        <img
                          src="/images/spinner.gif"
                          alt="spinner"
                          style="width: 50px"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary">
            {{ $t("football.live-score.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
      
  <script>
import axios from "axios";
export default {
  props: {
    teamId: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    teamMatchId: {
      type: Object,
      default: null,
    },
    findTeam: {
      type: String,
      default: null,
    },
  },

  mounted() {
    let that = this;
    window.$("#teamInformationPopup").on("shown.bs.modal", function () {
      that.teamDetails = null;

      that.getTeamDetails();
      that.getPlayersDetails();
    });

    window.$("#teamInformationPopup").on("hidden.bs.modal", function () {
      that.teamDetails = null;
    });
  },
  data() {
    return {
      matchList: null,
      teamDetails: null,
      loading: true,
      playersDetails: null,
    };
  },
  methods: {
    getTeamDetails() {
      this.loading = true;
      axios
        .get(
          `${this.BASE_SERVER_URI}/api/${this.type}/team-details/${this.teamId}`
        )
        .then((response) => {
          this.teamDetails = response.data.teamDetails;
        })
        .catch(() => {
          console.log("Error");
        })
        .finally(() => (this.loading = false));
    },

    getPlayersDetails() {
      this.loading = true;

      axios
        .get(
          `${this.BASE_SERVER_URI}/api/${this.type}/match-info/player-details/${this.teamMatchId}?team=${this.findTeam}`
        )
        .then((response) => {
          this.playersDetails = response.data.playerList;
        })
        .catch(() => {
          console.log("Error");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
  