<template>
  <div class="container-fluid p-0" style="min-height: 400px !important;width: 225% !important;margin-left: -960px;">
    <div class="row p-0 m-0">
      <div class="col-md-12 p-0 m-0">
        <div class="container fix-frame">

          <div class="d-flex justify-content-center row" style="background-color: white;">
            <div class="col-md-12" >
              <div class="row">
                <!-- begin::page title -->
                <div class="col-12 col-md-7 pr-0" style="margin-top: 10px;">
                  <h4 class="d-inline-block customPhoneH4TextSize">
                    <img
                      src="/images/icon-basketball.png"
                      width="45"
                      class="p-1 customPhoneH4Icon"
                    />
                    {{ $t("football.match-result.match-result") }}
                    <span v-if="loaderForSpecificDate">
                      <img
                        src="/images/spinner.gif"
                        alt="spinner"
                        style="width: 15px"
                      />
                    </span>
                  </h4>
                </div>
                <!-- end::page title -->
                <!-- begin::search section-->
                <div class="col-12 col-md-5 pl-0" style="margin-top: 10px;">
                  <div class="row p-0 m-0 mt-1">
                    <div
                      class="col-1 col-md-3 mr-0 pr-0"
                      style="
                        display: inline-flex;
                        border: 0px solid rgb(0, 0, 0);
                      "
                    ></div>
                    <div class="col-11 col-md-9 p-0 m-0 pl-1 text-right">
                      <div class="input-group mb-2 p-0">
                        <input
                          type="text"
                          id="inputLeagueTeamName"
                          v-model="inputLeagueTeamName"
                          :placeholder="
                            $t('football.live-score.league') +
                            ' / ' +
                            $t('football.live-score.team')
                          "
                          data-toggle="popover"
                          data-placement="top"
                          data-container="body"
                          class="form-control p-1 pl-1 customPhoneTextSize"
                          style="font-size: 80%"
                        />
                        <div class="input-group-append">
                          <button
                            v-on:click="searchTeamLeagueName()"
                            class="input-group-text bg-danger text-white btn p-1 customPhoneTextSize"
                          >
                            {{ $t("football.live-score.search") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end::search section -->
              </div>
              <!-- begin::previous buttons -->
              <div class="row mb-3 mt-2">
                <div class="col-12 col-md-5 col-lg-6 p-0 mb-2">
                  <div class="col-12">
                    <div class="bg-dark text-white p-2">
                      {{ $t("football.match-result.previous-7-day") }}
                    </div>
                  </div>
                  <div class="col-12 align-middle">
                    <button
                      id="previous-button"
                      style=""
                      type="button"
                      class="btn btn-sm m-1 text-white"
                      v-for="val in [-1, -2, -3, -4, -5, -6, -7]"
                      :key="val"
                      v-on:click="getSpecificMatchList(val, 1)"
                      :class="
                        dayClickedBtnVal == val ? 'btn-danger' : 'btn-primary'
                      "
                    >
                      {{ prevDate(val) }}
                    </button>
                  </div>
                </div>
                <!-- end::previous buttons -->
                <!-- begin::next buttons -->
                <div class="col-12 col-md-5 col-lg-6 p-0 mb-2">
                  <div class="col-12">
                    <div class="bg-dark text-white p-2">
                      {{ $t("football.match-result.next-7-day") }}
                    </div>
                  </div>
                  <div class="col-12 align-middle">
                    <button
                      id="next-button"
                      style=""
                      type="button"
                      class="btn btn-sm m-1 text-white"
                      fdprocessedid="a6slra"
                      v-for="val in [1, 2, 3, 4, 5, 6, 7]"
                      :key="val"
                      v-on:click="getSpecificMatchList(val, 1)"
                      :class="
                        dayClickedBtnVal == val ? 'btn-danger' : 'btn-primary'
                      "
                    >
                      {{ nextDate(val) }}
                    </button>
                  </div>
                </div>
                <!-- end::next buttons -->
              </div>
              <!-- begin::mobile version -->
              <div class="table-zqbf row d-block d-md-none" v-if="matchList">
                <center>
                  <div class="m-0 p-1 bg-dark text-white" style="width: 94%">
                    <div class="col-12 text-left" style="font-size: 16px">
                      {{ $date }}
                    </div>
                  </div>
                </center>
                <div
                  class="col-sm-12"
                  v-for="match in matchList"
                  :key="match.id"
                >
                  <div
                    class="row"
                    v-if="
                      match.league_Info &&
                      match.league_Info.cn_name &&
                      match.home_Info.en_name &&
                      match.away_Info.en_name
                    "
                  >
                    <div class="col-sm-12">
                      <div
                        class="row m-0 p-1"
                        :style="`background-color:${
                          match.league_Info && match.league_Info.primary_color
                            ? match.league_Info.primary_color
                            : 'black'
                        };`"
                      >
                        <div class="col-12 text-left text-white">
                          {{ changeTimeZone(match.match_timing) }}

                          <div class="btn p-1 text-white">
                            <span>
                              {{
                                match.league && this.$i18n.locale == "cn"
                                  ? match.league_Info.cn_name
                                  : match.league_Info.short_name
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-1">
                          <span
                            style="
                              position: relative;
                              top: 30px;
                              color: red;
                              left: 10px;
                            "
                          >
                          </span>
                        </div>
                        <div class="col-11">
                          <table>
                            <tr>
                              <td style="width: 50px">
                                <img
                                  v-if="match.home_Info.logo"
                                  :src="
                                    match.home_Info.logo
                                      ? match.home_Info.logo
                                      : '/images/default-basketball.jpg'
                                  "
                                  width="40"
                                  height="40"
                                />
                              </td>
                              <td style="width: 100px">
                                {{
                                  match.league_Info && this.$i18n.locale == "cn"
                                    ? match.home_Info.cn_name
                                    : match.home_Info.en_name || "Team Name"
                                }}
                              </td>
                              <td style="width: 50px">
                                {{ match.home_Info.section_1 || "-" }}
                              </td>
                              <td style="width: 50px">
                                {{ match.home_Info.section_2 || "-" }}
                              </td>
                              <td style="width: 50px">
                                {{ match.home_Info.section_3 || "-" }}
                              </td>
                              <td style="width: 50px">
                                {{ match.home_Info.section_4 || "-" }}
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 50px">
                                <img
                                  v-if="match.away_Info.logo"
                                  :src="
                                    match.away_Info.logo
                                      ? match.away_Info.logo
                                      : '/images/default-basketball.jpg'
                                  "
                                  width="40"
                                  height="40"
                                />
                              </td>
                              <td style="width: 200px">
                                {{
                                  match.league_Info && this.$i18n.locale == "cn"
                                    ? match.away_Info.cn_name
                                    : match.away_Info.en_name
                                }}
                              </td>
                              <td style="width: 50px">
                                {{ match.away_Info.section_1 || "-" }}
                              </td>
                              <td style="width: 50px">
                                {{ match.away_Info.section_2 || "-" }}
                              </td>
                              <td style="width: 50px">
                                {{ match.away_Info.section_3 || "-" }}
                              </td>
                              <td style="width: 50px">
                                {{ match.away_Info.section_4 || "-" }}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>

                      <div
                        class="row m-0 p-0"
                        style="background: rgb(251, 251, 251)"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end::mobile version -->
              <!-- begin::pc version -->
              <div
                class="row pt-2 pc-version"
                style="min-height: 279px; background-color: white"
              >
                <div class="col-md-12">
                  <table
                    class="table table-sm table-bordered table-hover text-center table-striped"
                  >
                    <tbody
                      class="mb-2"
                      v-for="match in matchList"
                      :key="match.id"
                    >
                      <template
                        v-if="
                          match.league_Info &&
                          match.league_Info.cn_name &&
                          match.home_Info.en_name &&
                          match.away_Info.en_name
                        "
                      >
                        <th
                          colspan="2"
                          class="text-left pl-4 text-white"
                          :style="`background-color:${
                            match.league_Info && match.league_Info.primary_color
                              ? match.league_Info.primary_color
                              : 'black'
                          };`"
                        >
                          <span>
                            {{
                              match.league_Info && this.$i18n.locale == "cn"
                                ? match.league_Info.cn_name
                                : match.league_Info.short_name
                            }}
                            :
                            <span>
                              <span title="status" v-if="match.status_id == 1">
                                {{ $t("football.live-score.soon") }}
                              </span>
                              <span title="status" v-if="match.status_id == 10">
                                {{ $t("state.finished-s") }}
                              </span>

                              <span
                                title="status"
                                v-else-if="match.status_id == 15"
                              >
                                {{ $t("football.live-score.to-be-determined") }}
                              </span>

                              <span
                                title="status"
                                v-else-if="match.status_id == 12"
                              >
                                {{ $t("football.live-score.cancelled") }}
                              </span>
                              <span
                                title="status"
                                v-else-if="match.status_id == 14"
                              >
                                {{ $t("basketball.live-score.put-off") }}
                              </span>

                              <span title="status" v-else> </span>
                            </span>
                          </span>
                        </th>
                        <th
                          bgcolor="#7EABD3"
                          title="First Quarter"
                          class="text-white"
                        >
                          {{ $t("basketball.state.fq") }}
                        </th>
                        <th
                          bgcolor="#7EABD3"
                          title="SecondQuarter"
                          class="text-white"
                        >
                          {{ $t("basketball.state.sq") }}
                        </th>
                        <th
                          bgcolor="#7EABD3"
                          title="ThirdQuarter"
                          class="text-white"
                        >
                          {{ $t("basketball.state.tq") }}
                        </th>
                        <th
                          bgcolor="#7EABD3"
                          title="FourthQuarter"
                          class="text-white"
                        >
                          {{ $t("basketball.state.frq") }}
                        </th>
                        <th bgcolor="#7EABD3" title="Half" class="text-white">
                          {{ $t("basketball.state.h") }}
                        </th>
                        <th bgcolor="#7EABD3" title="Full" class="text-white">
                          {{ $t("basketball.state.f") }}
                        </th>
                        <th
                          bgcolor="#7EABD3"
                          title="Points Difference"
                          class="text-white"
                        >
                          {{ $t("basketball.state.diff") }}
                        </th>
                        <th
                          bgcolor="#7EABD3"
                          title="Total Points"
                          class="text-white"
                        >
                          {{ $t("basketball.state.pts") }}
                        </th>
                        <th bgcolor="#7EABD3" class="text-white text-left">
                          {{ $t("basketball.league-details.information") }}
                        </th>

                        <tr>
                          <td rowspan="2" class="align-middle bg-white">
                            {{
                              new Date(match.match_timing).toLocaleDateString(
                                [],
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )
                            }}
                            <br />
                            {{ changeTimeZone(match.match_timing) }}
                          </td>
                          <td class="text-left">
                            <img
                              :src="
                                match.home_Info.logo
                                  ? match.home_Info.logo
                                  : '/images/default-basketball.jpg'
                              "
                              width="20"
                              height="20"
                            />

                            {{
                              this.$i18n.locale == "cn"
                                ? match.home_Info.cn_name
                                : match.home_Info.en_name
                            }}
                          </td>
                          <td class="align-middle">
                            {{
                              match.home_Info.section_1
                                ? match.home_Info.section_1
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            {{
                              match.home_Info.section_2
                                ? match.home_Info.section_2
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            {{
                              match.home_Info.section_3
                                ? match.home_Info.section_3
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            {{
                              match.home_Info.section_4
                                ? match.home_Info.section_4
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            <span style="color: blue">
                              {{
                                match.home_Info.section_1 +
                                  match.home_Info.section_2 !=
                                0
                                  ? match.home_Info.section_1 +
                                    match.home_Info.section_2
                                  : "/"
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            <b v-if="match.status_id == 10" style="color: red">
                              {{
                                match.home_Info.section_1 +
                                  match.home_Info.section_2 +
                                  match.home_Info.section_3 +
                                  match.home_Info.section_4 !=
                                0
                                  ? match.home_Info.section_1 +
                                    match.home_Info.section_2 +
                                    match.home_Info.section_3 +
                                    match.home_Info.section_4
                                  : "/"
                              }}
                            </b>
                            <b v-else style="color: blue">
                              {{
                                match.home_Info.section_1 +
                                  match.home_Info.section_2 +
                                  match.home_Info.section_3 +
                                  match.home_Info.section_4 !=
                                0
                                  ? match.home_Info.section_1 +
                                    match.home_Info.section_2 +
                                    match.home_Info.section_3 +
                                    match.home_Info.section_4
                                  : "/"
                              }}
                            </b>
                          </td>
                          <td class="align-middle">
                            {{ $t("basketball.state.h") }}:
                            {{
                              match.home_Info.section_1 +
                                match.home_Info.section_2 +
                                match.away_Info.section_1 +
                                match.away_Info.section_2 !=
                              0
                                ? match.home_Info.section_1 +
                                  match.home_Info.section_2 -
                                  (match.away_Info.section_1 +
                                    match.away_Info.section_2)
                                : "/"
                            }}
                          </td>
                          <td class="align-middle">
                            {{ $t("basketball.state.h") }}:
                            {{
                              match.home_Info.section_1 +
                                match.home_Info.section_2 +
                                match.away_Info.section_1 +
                                match.away_Info.section_2 !=
                              0
                                ? match.home_Info.section_1 +
                                  match.home_Info.section_2 +
                                  match.away_Info.section_1 +
                                  match.away_Info.section_2
                                : "/"
                            }}
                          </td>

                          <td rowspan="2" class="align-middle text-left">
                            <div class="pl-1">
                              <button
                                class="btn btn-outline-primary btn-sm"
                                fdprocessedid="ukdl8"
                                v-on:click="
                                  getLeagueDetails(match.competition_id)
                                "
                                data-toggle="modal"
                                data-target="#leaguePopup"
                              >
                                {{ $t("basketball.live-score.league") }}
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            <button class="btn p-0">
                              <img
                                data-toggle="modal"
                                data-target="#teamInformationPopup"
                                :src="
                                  match.away_Info.logo
                                    ? match.away_Info.logo
                                    : '/images/default-basketball.jpg'
                                "
                                width="20"
                                height="20"
                              />
                            </button>
                            <span class="p-0" style="display: inline-block">
                              {{
                                this.$i18n.locale == "cn"
                                  ? match.away_Info.cn_name
                                  : match.away_Info.en_name
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            {{
                              match.away_Info.section_1
                                ? match.away_Info.section_1
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            {{
                              match.away_Info.section_2
                                ? match.away_Info.section_2
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            {{
                              match.away_Info.section_3
                                ? match.away_Info.section_3
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            {{
                              match.away_Info.section_4
                                ? match.away_Info.section_4
                                : ""
                            }}
                          </td>
                          <td class="align-middle">
                            <span style="color: blue">
                              {{
                                match.away_Info.section_1 +
                                  match.away_Info.section_2 !=
                                0
                                  ? match.away_Info.section_1 +
                                    match.away_Info.section_2
                                  : "/"
                              }}
                            </span>
                          </td>
                          <td class="align-middle">
                            <b v-if="match.status_id == 10" style="color: red">
                              {{
                                match.away_Info.section_1 +
                                  match.away_Info.section_2 +
                                  match.away_Info.section_3 +
                                  match.away_Info.section_4 !=
                                0
                                  ? match.away_Info.section_1 +
                                    match.away_Info.section_2 +
                                    match.away_Info.section_3 +
                                    match.away_Info.section_4
                                  : "/"
                              }}
                            </b>
                            <b v-else style="color: blue">
                              {{
                                match.away_Info.section_1 +
                                  match.away_Info.section_2 +
                                  match.away_Info.section_3 +
                                  match.away_Info.section_4 !=
                                0
                                  ? match.away_Info.section_1 +
                                    match.away_Info.section_2 +
                                    match.away_Info.section_3 +
                                    match.away_Info.section_4
                                  : "/"
                              }}
                            </b>
                          </td>
                          <td class="align-middle">
                            {{ $t("basketball.state.f") }}:
                            {{
                              match.home_Info.section_1 +
                                match.home_Info.section_2 +
                                match.home_Info.section_3 +
                                match.home_Info.section_4 +
                                match.away_Info.section_1 +
                                match.away_Info.section_2 +
                                match.away_Info.section_3 +
                                match.away_Info.section_4 !=
                              0
                                ? match.home_Info.section_1 +
                                  match.home_Info.section_2 +
                                  match.home_Info.section_3 +
                                  match.home_Info.section_4 -
                                  (match.away_Info.section_1 +
                                    match.away_Info.section_2 +
                                    match.away_Info.section_3 +
                                    match.away_Info.section_4)
                                : "/"
                            }}
                          </td>
                          <td class="align-middle">
                            {{ $t("basketball.state.f") }}:
                            {{
                              match.home_Info.section_1 +
                                match.home_Info.section_2 +
                                match.home_Info.section_3 +
                                match.home_Info.section_4 +
                                match.away_Info.section_1 +
                                match.away_Info.section_2 +
                                match.away_Info.section_3 +
                                match.away_Info.section_4 !=
                              0
                                ? match.home_Info.section_1 +
                                  match.home_Info.section_2 +
                                  match.home_Info.section_3 +
                                  match.home_Info.section_4 +
                                  match.away_Info.section_1 +
                                  match.away_Info.section_2 +
                                  match.away_Info.section_3 +
                                  match.away_Info.section_4
                                : "/"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="10" class="p-3 bg-white"></td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- end::pc version -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <FOOTER />

  <!-- begin::league Information popup -->
  <LeagueDetailsModal :leagueId="leagueId" :type="'basketball'" />
  <!-- end::league information popup -->
</template>
<script>

import LeagueDetailsModal from "./../modals/league-details-modal.vue";
export default {
  components: {

    LeagueDetailsModal,
  },
  data() {
    return {
      matchList: null,
      currentPage: 1,
      loading: false,
      date: null,
      dayClickedBtnVal: null,
      dayNumber: -1,
      loaderForSpecificDate: null,
      specificPageNumber: null,
      totalPages: null,
      selectedHotLeague: null,
      leagueId: null,
      hotMatches: false,
    };
  },
  created() {
    this.getMatchList(this.dayNumber);
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if (!this.loading) {
        this.loading = true;
        if (this.hotMatches) {
          this.loading = false;
          return "";
        }
        this.getSpecificMatchList(this.dayNumber, 1);
      }
    });
  },

  methods: {
    prevDate(daysBefore) {
      let current = new Date();
      current.setTime(current.getTime() + daysBefore * 24 * 60 * 60 * 1000); // Subtract 7 days
      return `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`;
    },

    nextDate(daysBefore) {
      let current = new Date();
      current.setTime(current.getTime() + daysBefore * 24 * 60 * 60 * 1000); // Subtract 7 days
      return `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`;
    },
    getSpecificMatchList(day, pageNumer) {
      this.hotMatches = false;
      pageNumer = pageNumer || this.currentPage;
      if (this.totalPages <= pageNumer && day == this.dayNumber) {
        this.loading = false;
        return;
      }

      pageNumer = ++this.currentPage;
      this.loading = true;
      this.dayNumber = day;
      this.dayClickedBtnVal = day;
      this.loaderForSpecificDate = true;
      let url = `${this.BASE_SERVER_URI}/api6/basketball/matchlist/past/${day}?pageNumber=${pageNumer}`;

      this.$axios
        .get(url)
        .then((response) => {
          this.matchList = response.data.matchList;
          this.$date = this.matchList[0].match_time.split(" ")[0];
          this.totalPages = response.data.totalPages;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.loaderForSpecificDate = false;
        });
    },
    getLeagueDetails(id) {
      this.leagueId = id;
    },
    getMatchList(day) {
      this.dayNumber = day;
      this.loading = true;
      this.dayClickedBtnVal = day;
      this.loaderForSpecificDate = true;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/matchlist/past/${day}?pageNumber=1`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
          this.$date = this.matchList[0].match_timing.split(" ")[0];
          this.totalPages = response.data.totalPages;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.loaderForSpecificDate = false;
        });
    },

    searchTeamLeagueName() {
      let val = this.dayNumber;
      this.hotMatches = true;
      let inputkeyword = this.inputLeagueTeamName;
      this.loading = true;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/basketball/matchlist/past/${val}?pageNumber=1&locale=${this.$i18n.locale}&inputLeagueTeamName=${inputkeyword}`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    changeTimeZone(matchTime) {
      matchTime += "+08:00";
      if (this.$i18n.locale == "en") {
        let timeZoneUSA = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "America/New_York",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZoneUSA;
      } else if (this.$i18n.locale == "ty") {
        let timeZoneid = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "Asia/Jakarta",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZoneid;
      } else if (this.$i18n.locale == "vi") {
        let timeZonevi = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "Asia/Vientiane",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZonevi;
      } else if (this.$i18n.locale == "th") {
        var timeZoneth = new Date(matchTime)
          .toLocaleTimeString("en-US", {
            timeZone: "Asia/Bangkok",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
        return timeZoneth;
      } else
        return new Date(matchTime)
          .toLocaleString([], {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
          .slice(0, 5);
    },
    scoreColor(status_code) {
      if (status_code == 100) return "red";
      else if (status_code == 10) return "blue";
      else return "black";
    },
  },
};
</script>



<style scoped>
.btn-primary {
  background-color: rgb(52, 144, 220) !important;
}

@media (max-width: 400px) {
  .customPhoneTextSize {
    font-size: 12px;
  }

  .customPhoneH4TextSize {
    font-size: 20px;
  }

  .customPhoneH4Icon {
    width: 35px;
  }
}

@media only screen and (max-width: 991px) {
  .fix-frame {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .fix-frame {
    width: 1000px;
    margin: auto;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Tablets, iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Tablets, iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}
</style>