<template>
  <div>
    <div
      class="modal fade"
      id="matchStatics"
      tabindex="-1"
      role="dialog"
      aria-labelledby="matchStaticsLabel"
      aria-hidden="true"
    >
      <div role="document" class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 id="modal-team-title" class="modal-title">
              <img
                v-if="type == 'football'"
                src="/images/icon-football.png"
                width="38"
                class="p-1"
              />
              <img
                v-if="type == 'basketball'"
                src="/images/icon-basketball.png"
                width="38"
                class="p-1"
              />
              {{ $t("football.h2h.match-analysis") }}
            </h5>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              class="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-1 m-1">
            <div class="row p-0 m-0 justify-content-md-center">
              <div class="col-12 p-0 m-0">
                <table
                  class="table table-sm table-xs table-hover table-bordered text-center text-wrap custom-mobile table-bet"
                >
                  <tbody>
                    <tr v-if="loading">
                      <td colspan="12">
                        <br />
                        <br />
                        <img
                          src="/images/spinner.gif"
                          alt="spinner"
                          style="width: 70px"
                        />
                      </td>
                    </tr>
                  </tbody>
                  <template v-if="matchStatics && matchStatics.history">
                    <!-- begin::history -->
                    <tr>
                      <td
                        colspan="9"
                        style="background-color: #f7be57; color: white"
                      >
                        <b>{{ $t("football.h2h.history") }}</b>
                      </td>
                    </tr>
                    <!-- begin:: home -->
                    <template v-if="matchStatics.history.home_match_info">
                      <tr class="bg-primary">
                        <th
                          class="odds-table-live-title bg-primary text-white"
                          style="width: 150px !important"
                        >
                          {{ $t("football.live-score.home") }}
                        </th>
                        <th style="color: white">
                          {{ $t("football.h2h.team") }}
                        </th>
                        <th style="color: white">
                          {{ $t("football.h2h.ranking") }}
                        </th>
                        <th style="color: white">
                          {{ $t("football.h2h.score") }}
                        </th>
                        <th style="color: white">
                          {{ $t("football.h2h.ht-score") }}
                        </th>
                        <th style="color: white">
                          {{ $t("football.h2h.red-cards") }}
                        </th>
                        <th style="color: white">
                          {{ $t("football.h2h.yellow-cards") }}
                        </th>
                        <th style="color: white">
                          {{ $t("football.h2h.cornor") }}
                        </th>
                        <th style="color: white">
                          {{ $t("football.h2h.penality") }}
                        </th>
                      </tr>
                      <tbody
                        v-for="home in matchStatics.history.home_match_info"
                        :key="home.id"
                      >
                        <tr>
                          <td rowspan="2">
                            {{ changeTimeZone(home.match_time) }}
                          </td>
                          <td>
                            {{
                              this.$i18n.locale == "cn"
                                ? home.home_cn_name || ""
                                : home.home_en_name || ""
                            }}
                          </td>
                          <td>
                            {{ home.home_league_ranking || "-" }}
                          </td>
                          <td>
                            {{ home.home_team_half_time_score }}
                          </td>
                          <td>
                            {{ home.home_team_red_cards }}
                          </td>
                          <td>
                            {{ home.home_team_yellow_cards }}
                          </td>
                          <td>
                            {{ home.home_team_cornor }}
                          </td>
                          <td>
                            {{ home.home_team_overtime_score }}
                          </td>
                          <td>
                            {{ home.home_team_panalty_score }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              this.$i18n.locale == "cn"
                                ? home.away_cn_name || ""
                                : home.away_en_name || ""
                            }}
                          </td>
                          <td>
                            {{ home.away_league_ranking || "-" }}
                          </td>
                          <td>
                            {{ home.away_team_half_time_score }}
                          </td>
                          <td>
                            {{ home.away_team_red_cards }}
                          </td>
                          <td>
                            {{ home.away_team_yellow_cards }}
                          </td>
                          <td>
                            {{ home.away_team_cornor }}
                          </td>
                          <td>
                            {{ home.away_team_overtime_score }}
                          </td>
                          <td>
                            {{ home.away_team_panalty_score }}
                          </td>
                        </tr>
                      </tbody>
                    </template>

                    <!-- end:: home -->

                    <!-- begin::away -->
                    <template v-if="matchStatics.history.away_match_info">
                      <tr class="bg-primary text-white">
                        <th
                          class="odds-table-live-title bg-primary text-white"
                          style="width: 150px !important"
                        >
                          {{ $t("football.live-score.away") }}
                        </th>
                        <th>{{ $t("football.h2h.team") }}</th>
                        <th>{{ $t("football.h2h.ranking") }}</th>
                        <th>{{ $t("football.h2h.score") }}</th>
                        <th>{{ $t("football.h2h.ht-score") }}</th>
                        <th>{{ $t("football.h2h.red-cards") }}</th>
                        <th>{{ $t("football.h2h.yellow-cards") }}</th>
                        <th>{{ $t("football.h2h.cornor") }}</th>
                        <th>{{ $t("football.h2h.penality") }}</th>
                      </tr>
                      <tbody
                        v-for="away in matchStatics.history.away_match_info"
                        :key="away.id"
                      >
                        <tr>
                          <td rowspan="2">
                            {{ changeTimeZone(away.match_time) }}
                          </td>
                          <td>
                            {{
                              this.$i18n.locale == "cn"
                                ? away.home_cn_name || ""
                                : away.home_en_name || ""
                            }}
                          </td>
                          <td>
                            {{ away.home_league_ranking || "-" }}
                          </td>
                          <td>
                            {{ away.home_team_half_time_score }}
                          </td>
                          <td>
                            {{ away.home_team_red_cards }}
                          </td>
                          <td>
                            {{ away.home_team_yellow_cards }}
                          </td>
                          <td>
                            {{ away.home_team_cornor }}
                          </td>
                          <td>
                            {{ away.home_team_overtime_score }}
                          </td>
                          <td>
                            {{ away.home_team_panalty_score }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{
                              this.$i18n.locale == "cn"
                                ? away.away_cn_name || ""
                                : away.away_en_name || ""
                            }}
                          </td>
                          <td>
                            {{ away.away_league_ranking || "-" }}
                          </td>
                          <td>
                            {{ away.away_team_half_time_score }}
                          </td>
                          <td>
                            {{ away.away_team_red_cards }}
                          </td>
                          <td>
                            {{ away.away_team_yellow_cards }}
                          </td>
                          <td>
                            {{ away.away_team_cornor }}
                          </td>
                          <td>
                            {{ away.away_team_overtime_score }}
                          </td>
                          <td>
                            {{ away.away_team_panalty_score }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                    <!-- end::away -->
                  </template>
                  <template v-else>
                    <tr v-if="!loading">
                      <td>
                        {{ $t("basketball.live-score.temporarily-no-data") }}
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-secondary"
            >
              {{ $t("football.live-score.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    matchStaticId: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  mounted() {
    let that = this;
    window.$("#matchStatics").on("shown.bs.modal", function () {
      that.matchStatics = null;
      that.getMatchStatics();
    });

    window.$("#matchStatics").on("hidden.bs.modal", function () {
      that.matchStatics = null;
    });
  },

  data() {
    return {
      matchStatics: null,
      loading: false,
    };
  },
  methods: {
    getMatchStatics() {
      this.loading = true;
      axios
        .get(
          `${this.BASE_SERVER_URI}/api/${this.type}/match-analysis/statics/${this.matchStaticId}?fresh=false`
        )
        .then((response) => {
          this.matchStatics = response.data;
          this.loading = false;
          console.log("----------->", this.matchStatics);
        });
    },
    changeTimeZone(matchTime) {
      const moment = require("moment-timezone");
      var date = new Date(matchTime * 1000);
      if (this.$i18n.locale == "en") {
        return moment(date).tz("America/New_York").format("DD/MM/YYYY");
      } else if (this.$i18n.locale == "ty") {
        return moment(date).tz("Asia/Jakarta").format("HH:mm");
      } else if (this.$i18n.locale == "vi") {
        return moment(date).tz("Asia/Vientiane").format("HH:mm");
      } else if (this.$i18n.locale == "th") {
        return moment(date).tz("Asia/Bangkok").format("HH:mm");
      } else {
        return moment(date).tz("Asia/Shanghai").format("HH:mm");
      }
    },
  },
};
</script>
<!-- 23xmvkh8oednqg8 -->