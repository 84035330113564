<template>
  <div class="container-fluid bg-white m-0 p-0">
    <div class="container-fluid d-none d-md-block m-0 p-0">
      <div class="row m-0 p-0">
        <div class="col-md-2 d-none d-md-block"></div>
        <div class="col-12 col-md-8 m-0 p-0">
          <div class="row p-0 m-0">
            <div class="col-4 col-md-7 p-0">
              <a
                :href="'/' + this.$i18n.locale + '/live/football'"
                class="navbar-brand"
              >
                <img
                  alt="Logo"
                  src="/images/logo-live-id.png"
                  class="p-0 img-fluid"
                  style="max-width: 180px"
              /></a>
            </div>
            <div class="col-3 col-md-3 mt-1 pr-0">
              <div style="border: 0px solid rgb(0, 0, 0)">
                <div class="text-right">
                  <div class="d-none d-md-inline-block mt-2 mr-2">
                    <a
                      href="/bbs/register/?ref=53616c7465645f5f6538835c4706ee06fc0f60b45fdcd6abf8dd2dfe416617a0&amp;locale=ty"
                      class="p-1"
                    >
                      {{ $t("common.menu.register") }}
                    </a>
                    <a
                      href="/bbs/login/?ref=53616c7465645f5f6538835c4706ee06fc0f60b45fdcd6abf8dd2dfe416617a0&amp;locale=ty"
                      class="p-1"
                    >
                      {{ $t("common.menu.login") }}
                    </a>
                  </div>

                  <!---->
                </div>
              </div>
            </div>
            <div class="col-5 col-md-2 p-0 pl-0 pr-3">
              <div class="row">
                <div
                  class="col-12 text-right p-0 pt-2"
                  style="border: 0px solid rgb(0, 0, 0)"
                >
                  <div
                    class="dropdown bootstrap-select custom-lang show"
                    style="width: 120px"
                  >
                    <button
                      style="
                        background-color: rgb(52, 144, 220) !important;
                        color: white;
                      "
                      type="button"
                      tabindex="-1"
                      class="btn dropdown-toggle"
                      data-toggle="dropdown"
                      role="combobox"
                      aria-owns="bs-select-1"
                      aria-haspopup="listbox"
                      aria-expanded="false"
                    >
                      <div class="filter-option">
                        <div class="filter-option-inner">
                          <div class="filter-option-inner-inner">
                            <span id="defaul_language">
                              {{ defaultLanguage(this.$i18n.locale) }}
                              <img
                                :src="
                                  '/images/country-logos/lang-' +
                                  this.$i18n.locale +
                                  '.png'
                                "
                                width="25"
                                class="p-1"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </button>
                    <div class="dropdown-menu">
                      <div
                        class="inner show"
                        role="listbox"
                        id="bs-select-1"
                        tabindex="-1"
                        aria-activedescendant="bs-select-1-0"
                        style="max-height: 10px; min-height: 50px"
                      >
                        <ul
                          class="dropdown-menu inner show"
                          role="presentation"
                          style="margin-top: 0px; margin-bottom: 0px"
                        >
                          <li id="english" v-on:click="changeLanguage('en')">
                            <a
                              role="option"
                              class="dropdown-item selected"
                              id="english_language"
                              tabindex="0"
                              aria-setsize="5"
                              aria-posinset="1"
                              aria-selected="true"
                              ><span class="text" id="select_english">
                                <img
                                  :src="'/images/country-logos/lang-en.png'"
                                  width="35"
                                  class="p-1"
                                />English</span
                              ></a
                            >
                          </li>
                          <li id="indonesia" v-on:click="changeLanguage('ty')">
                            <a
                              role="option"
                              class="dropdown-item"
                              id="chinease_language"
                              tabindex="0"
                              aria-setsize="5"
                              aria-posinset="2"
                              ><span class="text" id="select_chinease">
                                <img
                                  src="/images/country-logos/lang-ty.png"
                                  width="35"
                                  class="p-1"
                                />Indonesia</span
                              ></a
                            >
                          </li>

                          <li id="thailand" v-on:click="changeLanguage('th')">
                            <a
                              role="option"
                              class="dropdown-item"
                              id="chinease_language"
                              tabindex="0"
                              aria-setsize="5"
                              aria-posinset="2"
                              ><span class="text" id="select_chinease">
                                <img
                                  src="/images/country-logos/lang-th.png"
                                  width="35"
                                  class="p-1"
                                />
                                ไทย</span
                              ></a
                            >
                          </li>
                          <li id="vietnamese" v-on:click="changeLanguage('vi')">
                            <a
                              role="option"
                              class="dropdown-item"
                              id="chinease_language"
                              tabindex="0"
                              aria-setsize="5"
                              aria-posinset="2"
                              ><span class="text" id="select_chinease">
                                <img
                                  src="/images/country-logos/lang-vi.png"
                                  width="35"
                                  class="p-1"
                                />
                                Việt Nam</span
                              ></a
                            >
                          </li>
                          <li id="chinease" v-on:click="changeLanguage('cn')">
                            <a
                              role="option"
                              class="dropdown-item"
                              id="chinease_language"
                              tabindex="0"
                              aria-setsize="5"
                              aria-posinset="2"
                              ><span class="text" id="select_chinease">
                                <img
                                  src="/images/country-logos/lang-cn.png"
                                  width="35"
                                  class="p-1"
                                />中文</span
                              ></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 d-none d-md-block"></div>
      </div>
    </div>
    <div class="container d-md-none m-0 p-0">
      <div class="row m-0">
        <header class="header-mobile">
          <a href="/" class="logo" style="margin-top: 5px"
            ><img
              src="/images/logo-live-id.png"
              class="p-0 img-fluid"
              style="max-width: 180px"
          /></a>
          <input type="checkbox" id="menu-btn" class="menu-btn" />
          <label for="menu-btn" class="menu-icon"
            ><span class="navicon"></span
          ></label>
          <div class="menu-lang">
            <div
              class="dropdown bootstrap-select custom-lang"
              style="width: 40px"
            >
              <button
                type="button"
                tabindex="-1"
                class="btn dropdown-toggle btn-primary1"
                data-toggle="dropdown"
                role="combobox"
                aria-owns="bs-select-2"
                aria-haspopup="listbox"
                aria-expanded="true"
                title="English"
              >
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">
                      {{ changeLanguageMobile(this.$i18n.locale) }}
                      <img
                        :src="
                          '/images/country-logos/lang-' +
                          this.$i18n.locale +
                          '.png'
                        "
                        title="en"
                        style="width: 24px !important"
                      />
                    </div>
                  </div>
                </div>
              </button>
              <div
                class="dropdown-menu"
                style="max-height: 602px; overflow: hidden; min-height: 150px"
              >
                <div
                  class="inner show"
                  role="listbox"
                  id="bs-select-2"
                  tabindex="-1"
                  aria-activedescendant="bs-select-2-1"
                  style="max-height: 586px; overflow-y: auto; min-height: 134px"
                >
                  <ul
                    class="dropdown-menu inner show"
                    role="presentation"
                    style="margin-top: 0px; margin-bottom: 0px"
                  >
                    <li id="indonesia" v-on:click="changeLanguage('ty')">
                      <a
                        role="option"
                        class="dropdown-item"
                        id="bs-select-2-0"
                        tabindex="0"
                        ><span class="text"
                          ><img
                            src="/images/country-logos/lang-ty.png"
                            title="ty"
                            style="width: 24px !important"
                          />
                          Indonesia</span
                        ></a
                      >
                    </li>
                    <li id="english" v-on:click="changeLanguage('en')">
                      <a
                        role="option"
                        class="dropdown-item selected"
                        id="bs-select-2-1"
                        tabindex="0"
                        aria-setsize="5"
                        aria-posinset="2"
                        aria-selected="true"
                        ><span class="text"
                          ><img
                            src="/images/country-logos/lang-en.png"
                            title="en"
                            style="width: 24px !important"
                          />
                          English</span
                        ></a
                      >
                    </li>
                    <li id="vietnamese" v-on:click="changeLanguage('vi')">
                      <a
                        role="option"
                        class="dropdown-item"
                        id="bs-select-2-2"
                        tabindex="0"
                        ><span class="text"
                          ><img
                            src="/images/country-logos/lang-vi.png"
                            title="vi"
                            style="width: 24px !important"
                          />
                          Việt Nam</span
                        ></a
                      >
                    </li>
                    <li id="thailand" v-on:click="changeLanguage('th')">
                      <a
                        role="option"
                        class="dropdown-item"
                        id="bs-select-2-3"
                        tabindex="0"
                        ><span class="text"
                          ><img
                            src="/images/country-logos/lang-th.png"
                            title="vi"
                            style="width: 24px !important"
                          />
                          ไทย</span
                        ></a
                      >
                    </li>
                    <li id="chinease" v-on:click="changeLanguage('cn')">
                      <a
                        role="option"
                        class="dropdown-item"
                        id="bs-select-2-4"
                        tabindex="0"
                        ><span class="text"
                          ><img
                            src="/images/country-logos/lang-cn.png"
                            title="cn"
                            style="width: 24px !important"
                          />
                          中文</span
                        ></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="menu-lang">
            <div
              class="dropdown bootstrap-select custom-lang"
              style="width: 40px"
            >
              <button
                type="button"
                tabindex="-1"
                class="btn dropdown-toggle btn-primary1"
                data-toggle="dropdown"
                role="combobox"
                aria-owns="bs-select-2"
                aria-haspopup="listbox"
                aria-expanded="true"
                title="English"
              >
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">
                      <img
                        src="/images/user.png"
                        title="en"
                        style="width: 24px !important"
                      />
                    </div>
                  </div>
                </div>
              </button>
              <div
                class="dropdown-menu"
                style="max-height: 102px; overflow: hidden"
              >
                <div
                  class="inner show"
                  role="listbox"
                  id="bs-select-2"
                  tabindex="-1"
                  aria-activedescendant="bs-select-2-1"
                  style="max-height: 486px; overflow-y: auto; min-height: 134px"
                >
                  <ul
                    class="dropdown-menu inner show"
                    role="presentation"
                    style="margin-top: 0px; margin-bottom: 0px"
                  >
                    <li id="login">
                      <a
                        role="menuitem"
                        href="/bbs/login/?ref=53616c7465645f5fbaafea451fae4a10a1a34fd13bd81579b4cdc95549c59dbe&amp;locale=en"
                        target="_self"
                        class="dropdown-item"
                      >
                        {{ $t("common.menu.login") }}
                      </a>
                    </li>
                    <li id="register">
                      <a
                        role="menuitem"
                        href="/bbs/register/?ref=53616c7465645f5fbaafea451fae4a10a1a34fd13bd81579b4cdc95549c59dbe&amp;locale=en"
                        target="_self"
                        class="dropdown-item"
                      >
                        {{ $t("common.menu.register") }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="menu-lang">
            <div style="border: 0px solid rgb(0, 0, 0)">
              <div class="text-right">
                <div class="d-none d-md-inline-block mt-2 mr-2">
                  <a
                    href="/bbs/register/?ref=53616c7465645f5fbaafea451fae4a10a1a34fd13bd81579b4cdc95549c59dbe&amp;locale=en"
                    class="p-1"
                  >
                    {{ $t("common.menu.register") }}
                  </a>
                  <a
                    href="/bbs/login/?ref=53616c7465645f5fbaafea451fae4a10a1a34fd13bd81579b4cdc95549c59dbe&amp;locale=en"
                    class="p-1"
                  >
                    {{ $t("common.menu.login") }}
                  </a>
                </div>

                <!---->
              </div>
            </div>
          </div>

          <ul class="menu">
            <li>
              <a :href="'/' + this.$i18n.locale + '/live/football'">
                <img src="/images/icon-football.png" width="35" class="pr-2" />
                {{ $t("common.menu.football") }}
              </a>
            </li>
            <li>
              <a :href="'/' + this.$i18n.locale + '/live/basketball'"
                ><img
                  src="/images/icon-basketball.png"
                  width="35"
                  class="pr-2"
                />{{ $t("common.menu.basketball") }}</a
              >
            </li>
            <li>
              <a :href="'/' + this.$i18n.locale + '/live/anchor'"
                ><img src="/images/video.png" width="35" class="pr-2" />{{
                  $t("common.menu.live")
                }}</a
              >
            </li>
            <li>
              <a :href="'/' + this.$i18n.locale + '/post/index'"
                ><img src="/images/news.png" width="35" class="pr-2" />{{
                  $t("common.menu.sports-news")
                }}</a
              >
            </li>
            <li>
              <a :href="'/' + this.$i18n.locale + '/video/index'"
                ><img src="/images/video.png" width="35" class="pr-2" />{{
                  $t("common.menu.hightlight")
                }}</a
              >
            </li>
            <li>
              <a :href="'/' + this.$i18n.locale + '/standings/index'"
                ><img src="/images/trophy.png" width="35" class="pr-2" />{{
                  $t("common.menu.standing")
                }}</a
              >
            </li>

            <li>
              <a :href="'/' + this.$i18n.locale + '/download-app'"
                ><img src="/images/app-icon.jpg" width="35" class="pr-2" />{{
                  $t("common.menu.download-app")
                }}</a
              >
            </li>
            <li>
              <a :href="'/' + this.$i18n.locale + '/alternate-url'"
                ><img
                  src="/images/weblink-icon.png"
                  width="35"
                  class="pr-2"
                />{{ $t("common.menu.alternate-url") }}</a
              >
            </li>
          </ul>
        </header>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-12 m-0 p-0">
    <nav
      class="navbar navbar-expand shadow-sm navbar-dark p-0 m-0"
      style="background-color: rgb(52, 144, 220)"
    >
      <div
        data-v-22f05ed0=""
        class="container-fluid p-0 pl-0 justify-content-md-center"
      >
        <div
          data-v-22f05ed0=""
          id="navbarSupportedContent"
          class="collapse navbar-collapse"
          style="flex-grow: 0"
        >
          <ul data-v-22f05ed0="" class="navbar-nav mr-auto mt-0 mt-lg-0">
            <li data-v-22f05ed0="" class="nav-item dropdown">
              <a
                data-v-22f05ed0=""
                href="#"
                id="navbar01"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="nav-link dropdown-toggle"
                ><img
                  src="/images/icon-football.png"
                  width="35"
                  class="p-1"
                />{{ $t("common.menu.football") }}
              </a>
              <div aria-labelledby="navbar01" class="dropdown-menu">
                <a
                  :href="'/' + this.$i18n.locale + '/live/football'"
                  class="dropdown-item"
                  >{{ $t("common.menu.football-live-score") }}</a
                >
                <span>
                  <a
                    class="dropdown-item"
                    :href="
                      '/' + this.$i18n.locale + '/live/football/match-schedule'
                    "
                    >{{ $t("common.menu.match-schedule") }}</a
                  >
                </span>

                <span>
                  <a
                    class="dropdown-item"
                    :href="
                      '/' + this.$i18n.locale + '/live/football/match-result'
                    "
                    >{{ $t("common.menu.match-result") }}</a
                  >
                </span>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                id="navbar01"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="nav-link dropdown-toggle"
                ><img
                  src="/images/icon-basketball.png"
                  width="35"
                  class="p-1"
                />{{ $t("common.menu.basketball") }}
              </a>
              <div aria-labelledby="navbar01" class="dropdown-menu">
                <a
                  :href="'/' + this.$i18n.locale + '/live/basketball'"
                  class="dropdown-item"
                  >{{ $t("basketball.live-score.basketball-live-score") }}</a
                >
                <span>
                  <a
                    class="dropdown-item"
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/live/basketball/match-schedule'
                    "
                    >{{ $t("common.menu.match-schedule") }}</a
                  >
                </span>

                <span>
                  <a
                    class="dropdown-item"
                    :href="
                      '/' + this.$i18n.locale + '/live/basketball/match-result'
                    "
                    >{{ $t("common.menu.match-result") }}</a
                  >
                </span>
              </div>
            </li>

            <li class="nav-item dropdown d-none d-md-block">
              <a
                :href="'/' + this.$i18n.locale + '/live/anchor'"
                id="navbar07"
                class="nav-link"
                ><img src="/images/video.png" width="35" class="p-1" />{{
                  $t("common.menu.live")
                }}
              </a>
            </li>
            <li class="nav-item dropdown d-none d-md-block">
              <a
                :href="'/' + this.$i18n.locale + '/post/index'"
                id="navbar04"
                class="nav-link"
                ><img src="/images/news.png" width="35" class="p-1" />{{
                  $t("common.menu.sports-news")
                }}
              </a>
            </li>
            <li class="nav-item dropdown d-none d-md-block">
              <a
                :href="'/' + this.$i18n.locale + '/video/index'"
                id="navbar05"
                class="nav-link"
                ><img src="/images/video.png" width="35" class="p-1" />{{
                  $t("common.menu.hightlight")
                }}
              </a>
            </li>
            <li class="nav-item dropdown d-none d-md-block">
              <a
                :href="'/' + this.$i18n.locale + '/standings/index'"
                id="navbar06"
                class="nav-link"
                ><img src="/images/trophy.png" width="35" class="p-1" />{{
                  $t("common.menu.standing")
                }}
              </a>
            </li>

            <li class="nav-item dropdown d-none d-md-block">
              <a
                :href="'/bbs?locale='+this.$i18n.locale"
                id="navbar06"
                class="nav-link"
                ><img src="/images/forum-icon.png" width="35" class="p-1" />{{
                  $t("common.menu.forum")
                }}
              </a>
            </li>

            <li class="nav-item dropdown d-none d-md-block">
              <a
                :href="'/' + this.$i18n.locale + '/download-app'"
                id="navbar09"
                class="nav-link"
                ><img src="/images/app-icon.jpg" width="35" class="p-1" />{{
                  $t("common.menu.download-app")
                }}
              </a>
            </li>
            <li class="nav-item dropdown d-none d-md-block">
              <a
                :href="'/' + this.$i18n.locale + '/alternate-url'"
                id="navbar09"
                class="nav-link"
                ><img src="/images/weblink-icon.png" width="35" class="p-1" />{{
                  $t("common.menu.alternate-url")
                }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      language: {
        en: "English",
        cn: "中文",
        vi: "Việt Nam",
        th: "ไทย",
        ty: "Indonesia",
      },
      languageMobile: {
        en: "",
        cn: "",
        vi: "",
        th: "",
        id: "",
      },
    };
  },
  methods: {
    changeLanguage(val) {
      if (val == "en") {
        this.$i18n.locale = "en";
        window.location.href = "/en/live/football";
      } else if (val == "cn") {
        this.$i18n.locale = "cn";
        window.location.href = "/cn/live/football";
      } else if (val == "ty") {
        this.$i18n.locale = "ty";
        window.location.href = "/ty/live/football";
      } else if (val == "vi") {
        this.$i18n.locale = "vi";
        window.location.href = "/vi/live/football";
      } else if (val == "th") {
        this.$i18n.locale = "th";
        window.location.href = "/th/live/football";
      } else {
        this.$i18n.locale = "en";
        window.location.href = "/en/live/football";
      }
    },
    defaultLanguage(val) {
      return this.language[val];
    },
    changeLanguageMobile(val) {
      return this.languageMobile[val];
    },
  },
};
</script>