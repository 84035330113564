<template>
  <div>
    <div
      class="modal fade"
      id="matchAnimate"
      tabindex="-1"
      role="dialog"
      aria-labelledby=""
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 id="modal-team-title" class="modal-title">
              <img
                v-if="scoreDetails && scoreDetails.sport_id == 1"
                src="/images/icon-football.png"
                width="38"
                class="p-1"
              />
              <img
                v-if="scoreDetails && scoreDetails.sport_id == 2"
                src="/images/icon-basketball.png"
                width="38"
                class="p-1"
              />
              {{ $t("football.live-score.live") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body p-0 m-0">
            <table
              class="table table-sm table-bordered table-hover text-center pb-0 mb-1"
            >
              <thead class="bg-primary text-white">
                <tr>
                  <th scope="col" style="width: 160px">
                    {{ $t("football.live-score.home-team") }}
                  </th>
                  <th scope="col" style="width: 100px">VS</th>
                  <th scope="col" style="width: 160px">
                    {{ $t("football.live-score.away-team") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row p-0 m-0">
                      <div
                        class="col-12 col-md-2 text-md-left p-0 m-0 order-md-2"
                      >
                        <img
                          v-if="scoreDetails.home_team_logo"
                          :src="
                            scoreDetails.home_team_logo.replace(
                              '_file',
                              'https://img.tysondata.com'
                            )
                          "
                          width="35"
                          class="border p-1"
                        />
                        <img
                          v-else
                          style="padding: 2px"
                          src="/images/default-football.jpg"
                          width="35"
                          class="border p-1"
                        />
                      </div>
                      <div
                        class="col-12 col-md-10 text-md-right p-1 m-0 order-md-1"
                      >
                        {{
                          this.$i18n.locale == "cn"
                            ? scoreDetails.home_team_name
                            : scoreDetails.home_team_en_name
                        }}
                      </div>
                    </div>
                  </td>
                  <td style="color: blue">
                    <span class="p-1">
                      <b v-if="scoreDetails.status == 2">
                        {{
                          scoreDetails.home_team_score +
                          "-" +
                          scoreDetails.away_team_score
                        }}
                      </b>
                      <b v-else-if="scoreDetails.status == 3">
                        {{
                          scoreDetails.home_team_score +
                          "-" +
                          scoreDetails.away_team_score
                        }}
                      </b>
                      <b v-else style="color: blue; font-size: 15px !important">
                        -
                      </b>
                    </span>
                  </td>
                  <td>
                    <div class="row p-0 m-0">
                      <div class="col-12 col-md-2 text-md-right p-0 m-0">
                        <img
                          v-if="scoreDetails.away_team_logo"
                          :src="
                            scoreDetails.away_team_logo.replace(
                              '_file',
                              'https://img.tysondata.com'
                            )
                          "
                          width="35"
                          class="border p-1"
                        />
                        <img
                          v-else
                          style="padding: 2px"
                          src="/images/default-football.jpg"
                          width="35"
                          class="border p-1"
                        />
                      </div>
                      <div class="col-12 col-md-10 text-md-left p-1 m-0">
                        {{
                          this.$i18n.locale == "cn"
                            ? scoreDetails.away_team_name
                            : scoreDetails.away_team_en_name
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="embed-responsive embed-responsive-16by9 div-animate"
              style="background: #0f1d20"
            >
              <div
                v-show="true"
                style="
                  background: #0f1d20;
                  position: absolute;
                  top: 48%;
                  left: 48%;
                "
                class="text-center"
              >
                <img
                  v-if="scoreDetails && scoreDetails.sport_id == 1"
                  src="/images/icon-football.png"
                  style="border: solid 0px #fff"
                  class="image-spin loading-icon"
                />
                <img
                  v-if="scoreDetails && scoreDetails.sport_id == 2"
                  src="/images/icon-basketball.png"
                  style="border: solid 0px #fff"
                  class="image-spin loading-icon"
                />
              </div>
              <iframe
                class="embed-responsive-item"
                :src="matchAnimationUrl"
                ref="frame"
                scrolling="yes"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t("football.live-score.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    matchAnimationId: {
      type: Object,
      default: null,
    },
    scoreDetails: {
      type: Object,
    },
  },

  mounted() {
    let that = this;
    window.$("#matchAnimate").on("shown.bs.modal", function () {
      that.matchAnimationUrl = null;
      that.getmatchAnimation();
    });

    window.$("#matchAnimate").on("hidden.bs.modal", function () {
      that.matchAnimationUrl = null;
    });
  },
  data() {
    return {
      matchAnimationUrl: null,
      loading: false,
    };
  },
  methods: {
    getmatchAnimation() {
      this.matchAnimationUrl = null;
      this.loading = true;
      axios
        .get(
          `${this.BASE_SERVER_URI}/api/get-match-animation/${this.matchAnimationId}`
        )
        .then((response) => {
          this.matchAnimationUrl = response.data.animationUrl;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.player-detail-list {
  padding: 0;
  margin: 0;
  border: solid 0px #000;
}
.player-detail-list label {
  padding: 0;
  margin: 0;
  padding: 2px 4px 2px 4px;
}
.player-detail-list div {
  padding: 0;
  margin: 0;
  padding: 2px 4px 2px 4px;
}
.image-spin {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
    transform-origin: center center;
  }
  50%,
  100% {
    transform: rotate(360deg);
    transform-origin: center center;
  }
}

.holds-the-iframe::before {
  position: relative;
  background: url(https://cdn.77577cf.com/assets/img/football-icon.png) center
    center no-repeat;
  border: solid 2px #fff;
}
.holds-the-iframe {
  background: #0f1d20;
}
.loading-icon {
  /* width: 55%; */
  width: 30px;
  height: 30px;
}
.div-animate {
  height: 300px;
}

/* Tablets, iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .loading-icon {
    /* width: 35%; */
    width: 15px;
    height: 15px;
  }
  .div-animate {
    height: 54vh;
  }
}

/* Tablets, iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .loading-icon {
    /* width: 35%; */
    width: 25px;
    height: 25px;
  }
  .div-animate {
    height: 54vh;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .loading-icon {
    /* width: 35%; */
    width: 25px;
    height: 25px;
  }
  .div-animate {
    height: 54vh;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .loading-icon {
    /* width: 35%; */
    width: 25px;
    height: 25px;
  }
  .div-animate {
    height: 54vh;
  }
}
</style>
