<template>
  <br />
  <span>
    <div class="container p-0" style="min-height: 400px !important">
      <div class="row p-0 m-0">
        <div class="col-md-12 p-0 m-0">
          <div class="live-container fix-frame">
            <div class="b-overlay-wrap position-relative">
              <div
                class="row anc-content-list pl-2 pr-2"
                style=""
                v-if="liveAnchor"
              >
                <div
                  class="col-6 col-md-3 p-1"
                  v-for="anchor in liveAnchor"
                  :key="anchor.id"
                >
                  <a
                    v-on:click="getAnchorDetails(anchor.is_live, anchor.id)"
                    :class="anchor.is_live == 0 ? 'disabled' : ''"
                    style="cursor: pointer"
                  >
                    <article class="card mb-2 mt-2">
                      <div class="col p-0">
                        <img
                          class="thumbnail img-fluid w-100 mx-auto d-block"
                          v-if="anchor.live.cover"
                          :src="anchor.live.cover"
                        />
                        <br />
                      </div>

                      <div>
                        <div>
                          <div class="d-flex flex-row">
                            <div class="mr-1">
                              <span
                                class="b-avatar badge-secondary rounded-circle"
                                style="width: 3.5rem; height: 3.5rem"
                                ><span class="b-avatar-img"
                                  ><img
                                    class="thumbnail"
                                    v-if="anchor.avatar"
                                    :src="anchor.avatar"
                                    alt="avatar" /></span
                              ></span>
                            </div>
                            <div class="ml-1">
                              <span
                                class="mr-auto anc-title-vi font-weight-bold"
                                style="text-decoration: none"
                              >
                                {{ anchor ? anchor.name : "" }}
                              </span>
                              <div class="anchor-title">
                                {{ anchor ? anchor.title : "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>

                    <div class="anc-offline-label" v-if="anchor.is_live == 0">
                      <div class="container h-100">
                        <div class="row align-items-center h-100">
                          <div class="text-center mx-auto">
                            <h5 style="margin-top: -60px !important">
                              {{ $t("page-live.anchor-offline") }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-12 p-6 mt-2">
                  <div class="row" v-if="livemeta">
                    <div class="col-10 d-none d-md-block">
                      <div
                        class="d-flex justify-content-center"
                        style="cursor: pointer"
                      >
                        <paginate
                          :page-count="livemeta ? livemeta.last_page : 0"
                          :page-range="10"
                          :margin-pages="2"
                          :click-handler="pagination"
                          :prev-text="'‹'"
                          :next-text="'›'"
                          :container-class="'pagination'"
                          :page-class="'page-item'"
                        >
                        </paginate>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="loading">
        <div class="col-12 d-md-block">
          <div class="d-flex justify-content-center">
            <img
              src="/images/spinner.gif"
              alt="Image not Found"
              style="width: 20px"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="live_stream_popup"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <center>
              <h3>{{ $t("page-live.info") }}</h3>
              <p>{{ $t("page-live.anchor-still-offline") }} "</p>
            </center>
          </div>
        </div>
      </div>
    </div>
  </span>
  <FOOTER />
</template>
<script>
import axios from "axios";
import router from "../../router";
import Paginate from "vuejs-paginate-next";
export default {
  components: {
    Paginate,
  },
  data() {
    return {
      liveAnchor: null,
      livemeta: null,
      pageNum: 1,
      loading: false,
    };
  },
  mounted() {
    console.log("Component is Mounted");
  },
  created() {
    this.getLiveAnchorList();
  },
  methods: {
    getLiveAnchorList() {
      this.loading = true;
      axios
        .get(
          ` https://bbs.77577.live/api/v1/live-stream/anchor?locale=${this.$i18n.locale}&page=${this.pageNum}&live=null`
        )
        .then((response) => {
          this.liveAnchor = response.data.anchor.data;
          this.livemeta = response.data.anchor;
          this.loading = false;
        });
    },
    getAnchorDetails(liveStatus, anchorId) {
      if (!liveStatus) window.$("#live_stream_popup").modal("show");
      else router.push(`/${this.$i18n.locale}/live/anchor-detail/${anchorId}`);
    },
    pagination(pageNum) {
      this.pageNum = pageNum;
      this.getLiveAnchorList();
    },
  },
};
</script>
<style scoped>
.anc-offline-label {
  background: none repeat scroll 0 0 #ffffff;
  opacity: 0.5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.thumbnail {
  position: relative;
  max-height: 12rem;
}
</style>
